@import "../../styles/style.scss";

.calender-table {
  display: grid;
  margin-top: 1rem;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  border-collapse: collapse;
  .day {
    display: grid;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    padding: 0.5rem 0.25rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.125);
  }
}
.calender-head {
  display: grid;
  row-gap: 1rem;
  .calender-head-content {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  h4 {
    margin: 0rem;
  }
}
