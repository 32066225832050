@import "../styles/style.scss";

.inventories-page {
  display: grid;
  table {
    border-collapse: collapse;
    text-align: left;
    margin-top: 1rem;
    font-size: 0.85rem;
    width: 100%;
    tbody {
      td {
        padding: 0.5rem 0rem;
      }
    }
    th,
    td {
      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8),
      &:nth-of-type(9) {
        display: none;
      }
    }
  }
}

@include responsive(small) {
}

@include responsive(medium) {
}

@include responsive(large) {
}
