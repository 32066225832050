@import "../../styles/style.scss";

.customer-details-wrapper {
  margin-top: 1rem;
  box-shadow: 0px 2px 12px rgba($color: #000000, $alpha: 0.125);
  border-radius: 0.5rem;
  .details-head {
    display: flex;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    background: white;
  }
  .customer-details {
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 0.5rem;
    padding: 1rem;
    div {
      display: grid;
      align-items: center;
      &:nth-of-type(1n + 3) {
        margin-top: 0.5rem;
      }
      svg {
        margin-right: 0.5rem;
        align-self: flex-start;
        font-size: 1rem;
      }
      ul {
        font-size: 0.8rem;
        li {
          font-style: italic;
        }
        span {
          font-weight: bold;
          font-style: normal;
        }
      }
    }
  }
  .service-actions {
    display: flex;
    justify-content: right;
    button {
      margin-left: 1rem;
    }
  }
  .details-container {
    display: grid;
    grid-template-columns: 1;
    margin-bottom: 1.5rem;
    align-items: flex-start;
    overflow-x: hidden;
    box-shadow: 0px 2px 8px rgba($color: #000000, $alpha: 0.125);
    border-radius: 0.25rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    .title-head {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 1.25rem 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    }
    .section-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    }
    .title-foot {
      display: flex;
      margin: 0rem;
      align-items: center;
      padding: 0rem 1rem;
      justify-content: right;
      .actions {
        display: flex;
      }
    }
    .form-container {
      display: grid;
      align-content: flex-start;
      align-items: flex-start;
      grid-template-columns: 1fr;
      width: 100%;
      row-gap: 0.75rem;
      column-gap: 1rem;
      padding: 1.25rem 1rem;
      span {
        font-size: 0.9rem;
      }
      textarea {
        height: 200px;
        width: 100%;
        padding: 1rem;
        resize: none;
        border-radius: 0.25rem;
      }
    }
  }
  @include responsive(medium) {
    .details-container {
      .form-container {
        grid-template-columns: 1fr;
      }
    }
  }
}
