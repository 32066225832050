@import '../styles/style.scss';

.premium {
    display: grid;
    .drop-down {
        display: grid;
        margin-top: 1rem;
        .drop-down-title {
            display: flex;
            justify-content: space-between;
            border: 1px solid rgba($color: #000000, $alpha: .1);
            padding: 1rem 1rem;
            h4 {
                color: rgba($color: #2b2b2b, $alpha: 1.0);
            }
        }
        .drop-down-content {
            padding: .5rem 1rem;
            p {
                font-size: .85rem;
                margin-top: .5rem;
            }
            a {
                text-decoration: none;
                color: $main-color-blue;
            }
            ul {
                margin-top: .5rem;
                li {
                    font-size: .9rem;
                    font-weight: bold;
                }
            }
        }
    }
}