@import "../../styles/style.scss";

.update-account {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  margin: 0.5rem 0rem 0.5rem 0rem;
  border-radius: 0.25rem;
  // padding: .5rem;
  // border: 1px solid rgba($color: #000000, $alpha: .125);
  p {
    font-size: 0.75rem;
    color: rgba($color: #2f2f2f, $alpha: 1);
    padding: 0rem 0rem 0.25rem 0rem;
  }
  button {
    margin-top: 0.5rem;
    margin: 0rem;
    padding: 0rem;
    font-size: 0.75rem;
    height: max-content;
  }
  svg {
    font-weight: bold;
    font-size: 0.9rem;
    color: #333;
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
}
