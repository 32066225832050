@import "../../styles/style.scss";

.details-container {
  .title-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    h4 {
      margin-bottom: 0rem;
    }
    button {
      margin: 0rem;
    }
  }
  .invoice-items-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.75rem;
    align-items: center;
    padding: 1.25rem 1rem;
    .invoice-line-item-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1rem;
      row-gap: 0.75rem;

      div {
        display: grid;
        row-gap: 0.25rem;
        width: 100%;
      }
      input {
        margin-bottom: 0.5rem;
      }
      h4 {
        margin-bottom: 0rem;
      }
    }
  }
}
@include responsive(medium) {
  .details-container {
    .form-container {
      grid-template-columns: 1fr 2fr;
    }
    .invoice-items-container {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 0.75rem;
      align-items: center;
      padding: 1.25rem 1rem;
      width: 100%;
      .invoice-line-item-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
        column-gap: 1rem;

        div {
          display: grid;
          row-gap: 0.75rem;
          width: 100%;
          align-items: center;
        }
        h4 {
          margin-bottom: 0rem;
        }
        input {
          margin-bottom: 0.5rem;
        }
        button {
          justify-self: right;
          height: max-content;
        }
      }
      span {
        font-size: 0.9rem;
      }
    }
  }
}
