@import "./style.scss";

button {
  margin: 0rem;
  height: 2rem;
}
.style-one-btn {
}

.dashboard-btn {
}
