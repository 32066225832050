@import "../../styles/style.scss";

.nav-bar {
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 0rem 1rem;
  height: 3rem;
  background: rgb(248, 248, 248);

  svg {
    font-size: 2rem;
    color: #777;
  }
  div {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.85rem;
    img {
      height: 1.65rem;
      margin-right: 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.side-menu-wrapper {
  display: grid;
  position: fixed;
  align-items: flex-start;
  width: 66%;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  background: rgb(255, 255, 255);
  transform: translateX(100%);
  height: calc(100vh - 3rem);
  transition: 300ms ease-in-out;
  &.open {
    transform: translateX(0%);
    overflow-y: scroll;
    height: 100vh;
    position: fixed;
  }
  button {
    display: grid;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    padding: 0rem 2rem;
    background: transparent;
    height: 2rem;
    border: none;
    color: white;
  }
  ul {
    display: grid;
    width: 100%;
    margin: 0rem;
    padding: 0rem;
    li {
      display: grid;
      justify-content: right;
      align-items: center;
      align-content: right;
      height: 2.5rem;
      width: 100%;
      border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
      button,
      a {
        display: grid;
        margin: 0rem;
        align-items: center;
        font-size: 0.7rem;
        text-decoration: none;
        justify-self: center;
        justify-content: right;
        justify-items: center;
        padding: 0rem 1rem;
        background: transparent;
        height: 2.25rem;
        border: none;
        color: #333;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
      &.header {
        display: grid;
        align-content: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75rem;
        height: 2rem;
        background: $main-color-blue;
        color: white;
        letter-spacing: 0.125rem;
        padding: 0rem 1rem;
      }
    }
  }
}
.desktop-nav {
  display: none;
}
@include responsive(small) {
}

@include responsive(medium) {
  .side-menu-wrapper {
    display: grid;
    align-items: flex-start;
    width: 33%;
  }
}

@include responsive(large) {
  .side-menu-wrapper {
    display: none;
  }
  .nav-bar {
    display: none;
  }
  .desktop-nav {
    display: flex;
    position: fixed;
    z-index: 2;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 0rem 7rem;
    height: 3.5rem;
    color: white;
    background: rgb(248, 248, 248);
    box-shadow: 0px 2px 2px rgba($color: #000000, $alpha: 0.125);

    svg {
      font-size: 2rem;
      color: white;
    }
    div {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.85rem;
      img {
        height: 1.65rem;
        margin-right: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
    ul {
      display: flex;
      height: 3.5rem;
      align-items: center;
      li {
        margin-left: 2rem;
        align-items: center;
        a {
          text-decoration: none;
          color: #6bbaf1;
          transition: all ease-in-out 200ms;
          &:hover {
            color: rgb(184, 184, 184);
          }
        }
        button {
          display: grid;
          margin: 0rem;
          background: transparent;
          color: $main-color-green;
          transition: ease-in-out 200ms all;
          &:hover {
            color: rgb(187, 187, 187);
          }
        }
      }
    }
  }
}
