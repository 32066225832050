@import "../styles/style.scss";

.service-log {
  .service-log-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin: 0rem;
    }
  }
  input {
    margin: 1rem 0rem 0.5rem 0rem;
  }
}
