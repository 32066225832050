@import "../../styles/style.scss";

h4 {
  margin-bottom: 1rem;
}
.details-section {
  display: grid;
  row-gap: 1rem;
  .new-section {
    border: 1px solid rgba($color: #000000, $alpha: 0.125);
    border-radius: 0.25rem;
    .section-title-head {
      display: flex;
      background: white;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      font-weight: 500;
      color: white;
      background: $main-color-blue;
      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      svg {
        display: grid;
        font-size: 1rem;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .section-general-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: white;
      padding: 0.5rem;
      font-size: 0.8rem;
      ul {
        li {
          color: #333;
          font-size: 0.725rem;
          &.title {
            font-size: 0.8rem;
            font-weight: 500;
            color: #777;
            margin-bottom: 0rem;
          }
          &.spacer {
            height: 0.25rem;
          }
        }
      }
    }
  }
  .actions-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 2fr;
    column-gap: 1rem;
    button {
      width: 100%;
    }
  }
}
p {
  font-size: 0.8rem;
}

@include responsive(medium) {
  .details-section {
    .actions-wrapper {
      button {
        width: max-content;
        padding: 0.65rem 2rem;
      }
    }
  }
}

@include responsive(large) {
  main {
    margin: auto;
    margin-top: 4rem;
    max-width: 1200px;
  }
}
