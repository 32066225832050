@import "../styles/style.scss";

.log-a-service {
  display: grid;
  input {
    margin-top: 1rem;
  }
  .log-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .results {
    display: grid;
    margin-top: 1rem;
  }
}

@include responsive(medium) {
  .log-a-service {
  }
}
