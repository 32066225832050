@import "./style.scss";

.general-page {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 2rem;
  width: 100%;
  h1 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3 {
    color: #444;
    text-transform: uppercase;
  }
  h4 {
    margin: 0;
  }

  .page-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .title-nav {
      display: flex;
      width: 100%;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      color: #9f9f9f;
      svg {
        font-size: 1rem;
      }
    }
    button {
      display: flex;
      margin: 0rem;
      height: 2rem;
      align-items: center;
      align-content: center;
    }
  }
  .new-section {
    margin: 0.5rem 0rem;
  }
  .page-section-head-one {
    display: flex;
    margin-top: 0.5rem;
    width: 100%;
    background: white;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 500;
    color: white;
    background: $main-color-blue;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.125);
    svg {
      display: grid;
      font-size: 1rem;
      color: white;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .image-uploads-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    width: 100%;
    gap: 0.5rem;
    div {
      display: grid;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
  .page-section-head-two {
    display: flex;
    margin-top: 0.5rem;
    width: 100%;
    background: white;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(94, 94, 94);
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.125);
    button {
      margin: 0rem;
    }
    .input-wrapper {
      display: grid;
      input[type="file"] {
        display: none;
      }
      .custom-file-upload {
        display: grid;
        column-gap: 0.5rem;
        justify-content: center;
        text-align: center;
        padding: 0.5rem;
        border: 1px solid #ccc;
        display: inline-block;
        cursor: pointer;
      }
    }

    svg {
      display: grid;
      font-size: 1rem;
      color: white;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .page-section-content-one {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    background: white;
    padding: 0.5rem;
    font-size: 0.9rem;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.125);
    margin-bottom: 1rem;
    ul {
      li {
        span {
          font-weight: bold;
          margin-right: 0.5rem;
        }
        color: #333;
        font-size: 0.725rem;
        &.title {
          font-weight: 500;
          color: #777;
          margin-bottom: 0rem;
        }
        &.spacer {
          height: 0.25rem;
        }
      }
    }
  }
  .page-section-content-two {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    background: white;
    padding: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.125);
    ul {
      li {
        span {
          font-weight: bold;
          margin-right: 0.5rem;
        }
        color: #333;
        font-size: 0.725rem;
        &.title {
          font-weight: 500;
          color: #777;
          margin-bottom: 0rem;
        }
        &.spacer {
          height: 0.25rem;
        }
      }
    }
  }
  .actions-wrapper {
    display: flex;
    justify-content: space-around;
    margin: auto;
    width: 100%;
    margin-top: 0.5rem;
    align-items: center;
    align-content: center;
    button {
      margin: 0rem;
      margin-right: 0.25rem;
    }
  }
}

@include responsive(medium) {
  .general-page {
    .image-uploads-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .page-section-content-one {
      row-gap: 0rem;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include responsive(large) {
  .general-page {
    .image-uploads-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
