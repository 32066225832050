@import '../../styles/style.scss';

.revenue-card {
    display: grid;
    width: 100%;
    padding: .5rem 0rem;
    background: rgba(255,255,255, 1);
    margin-bottom: .5rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: .125);
    border: 1px solid rgba($color: #000000, $alpha: .125);
    padding: .5rem;
    border-radius: .25rem;
f        // background: rgba($color: #ffffff, $alpha: .5);
    .head {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        text-transform: capitalize;
        button {
            margin: 0rem;
        }
    }
    button {
        display: flex;
        align-items: center;
        font-size: .75rem;
        font-weight: 500;
        background-color: transparent;
        color: rgb(138, 138, 138);
        padding: 0rem 0rem;
        border-radius: .25rem;
        height: max-content;
        margin: 0rem 0rem;
        svg {
            font-size: 1rem;
        }
    }
    h5 {
        color: rgba($color: #000000, $alpha: .5);
        height: 1.25rem;
    }
}