@import '../../styles/style.scss';

.add-customer-alert-wrapper {
    display: grid;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgb(112, 112, 112, .75);
    left: 0;
    top: 0;
    z-index: 996;
    .add-customer-alert {
        display: grid;
        margin: auto;
        margin-top: 25%;
        height: max-content;
        align-items: flex-start;
        align-content: flex-start;
        width: 90vw;
        background: rgba($color: rgb(255, 255, 255), $alpha: 1);
        box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
        border-radius: .5rem;
        p {
            font-size: .95rem;
        }
        .alert-head {
            display: flex;
            align-items: center;
            align-content: center;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .25);
            padding: .75rem 1rem;
            font-weight: bold;
            p {
                display: flex;
                svg {
                    display: grid;
                    align-self: center;
                    font-size: 1.125rem;
                    &.warning {
                        color: red;
                        margin-right: .35rem;
                    }
                    &.success {
                        color: red;
                        margin-right: .35rem;
                    }
                }
            }

        }
        .content {
            padding: .5rem .25rem;
            p {
                padding: .3rem 1rem;
                font-size: .75rem;
            }
        }
        .button-container {
            display: flex;
            align-items: center;
            margin-top: .5rem;
            justify-content: space-between;
            padding: 1rem;
            button {
                margin-bottom: 0rem;
            }
        }
    }
}

@include responsive(medium) {
    .add-customer-alert-wrapper {
        background: rgb(112, 112, 112, .75);
        .add-customer-alert {
            margin: auto;
            margin-top: 15%;
            width: 500px;
            .button-container {
                justify-content: space-between;
            }
        }
    }
}
