@import "../../../styles/style.scss";

.dash-nav {
  display: flex;
  width: 100%;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  ul {
    display: flex;
    background: transparent;
    height: max-content;
    li {
      width: max-content;
      margin-right: 0.75rem;
      a {
        text-transform: capitalize;
        text-decoration: none;
        font-style: normal;
        font-size: 0.7rem;
        color: rgba($color: #ffffff, $alpha: 1);
        padding: 0.35rem 0.5rem;
        background: $main-color-blue;
        border-radius: 0.25rem;
      }
    }
  }
}
