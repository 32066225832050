@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  box-sizing: border-box;
  margin: 0rem;
  padding: 0rem;
  font-family: "Roboto", sans-serif;
}
$main-color-green: #99c24d;
$main-color-blue: #3fa9f5;
.index-page {
  background: black;
}

html {
  width: 100vw;
  overflow-x: hidden;
}
body {
  background: rgb(255, 255, 255);
}
.global-wrapper {
  display: grid;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
input[type="text"],
input[type="number"] {
  height: 100%;
  width: 100%;
  height: 2.35rem;
  margin: 0.5rem 0rem;
  border: none;
  padding: 1rem 1rem;
  border-radius: 0.25rem;
  border: 2px solid transparent;
  background: rgba($color: #f5f5f5, $alpha: 1);
  &:active,
  &:focus {
    border: 1px solid rgba($color: #000000, $alpha: 0.25);
    outline: 0rem;
  }
}
main {
  display: grid;
  margin-top: 4rem;
  padding: 0rem 1rem;
  width: 100%;
  min-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
}
.error {
  color: red;
}
h5 {
  font-weight: 500;
  color: #333;
}
button {
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  border: none;
  width: max-content;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  transition: ease-in-out 200ms all;
  &.grey {
    background: rgba($color: #000000, $alpha: 0.5);
  }
  &.blue {
    background: $main-color-blue;
  }
  &:hover {
    cursor: pointer;
  }
  &.white-link {
    display: flex;
    width: max-content;
    justify-self: left;
    text-align: left;
    color: rgb(255, 255, 255);
    background: transparent;
    text-decoration: underline;
    padding: 0rem;
    margin: 0rem;
  }
  &.link {
    display: flex;
    width: max-content;
    justify-self: left;
    text-align: left;
    color: black;
    font-weight: 500;
    background: transparent;
    text-decoration: underline;
    padding: 0rem;
    margin: 0rem;
    &.green {
      color: $main-color-blue;
    }
    &.grey {
      color: #333;
    }
    &.lightgrey {
      color: #666;
    }
  }
  &.action-style-two {
    background: #777;
    &:hover {
      background: #333;
    }
  }

  &.warning {
    background: rgba($color: #ff6363, $alpha: 1);
    &:hover {
      background: #ff3b3b;
    }
  }
  &.smaller {
    font-size: 0.75rem;
    font-weight: normal;
    padding: 0.25rem 0.5rem;
  }
  &.action {
    background: $main-color-green;
    &:hover {
      background: rgb(120, 163, 41);
    }
  }
}
ul {
  list-style-type: none;
}

$breakpoints: (
  "small": 400px,
  "medium": 768px,
  "large": 1200px,
);

@mixin responsive($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);
  @if $value !=null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
  }
}
@include responsive(small) {
  main {
    margin: auto;
    margin-top: 4rem;
    width: 100%;
  }
}

@include responsive(medium) {
  main {
    margin: auto;
    margin-top: 4rem;
    max-width: 800px;
  }
}

@include responsive(large) {
  main {
    margin: auto;
    margin-top: 4rem;
    max-width: 1200px;
  }
}
