@import "../../styles/style.scss";

.my-invoices {
  display: grid;
  .mobile-invoice-list-container {
    display: grid;
    width: 100%;
    background: rgba($color: #ffffff, $alpha: 0.25);
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    padding: 0rem 1rem 1rem 1rem;
    &:hover {
      cursor: pointer;
    }
    .invoice-list-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      span {
        display: none;
        font-size: 1rem;
      }
      button {
        margin: 0rem;
      }
    }
    .search {
      input {
        width: 100%;
        height: 2.5rem;
        padding: 1rem 1rem;
        border-radius: 0.25rem;
        background: rgba($color: #979797, $alpha: 0.125);
        border: 1px solid rgba($color: #979797, $alpha: 0.25);
      }
    }
    .controller {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 1rem;
      div {
        display: flex;
        height: 1.25rem;
        background: rgba($color: #808080, $alpha: 1);
        color: white;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0rem 1rem;
        font-size: 0.75rem;
        border-radius: 0.125rem;
        font-weight: bold;
      }
    }
    .invoice-table {
      border-collapse: collapse;
      width: 100%;
      thead {
      }
      tbody {
        tr {
          display: flex;
          width: 100%;
          justify-content: space-around;
          padding: 0.25rem 0rem;
          box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.125);
          margin: 1rem 0rem;
          border-radius: 0.5rem;
          td {
            display: grid;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-size: 0.85rem;
            padding: 0rem 1rem;
            &:nth-of-type(1) {
              justify-content: left;
              text-align: left;
            }
            &:nth-of-type(3) {
              justify-content: right;
              text-align: right;
            }
            span {
              padding: 0.125rem 0rem;
              &.bold {
                font-weight: bold;
              }
              &.date {
                font-size: 0.75rem;
                font-weight: bold;
                color: rgba($color: #000000, $alpha: 0.5);
              }
              &.amount {
                font-weight: normal;
                &.paid {
                  color: $main-color-green;
                  font-weight: normal;
                }
                &.unpaid {
                  color: black;
                  font-weight: normal;
                }
                &.draft {
                  color: black;
                  font-weight: normal;
                }
              }
              &.paid {
                font-weight: bold;
                text-transform: capitalize;
              }
              &.draft {
                color: orange;
                font-weight: bold;
                text-transform: capitalize;
              }
              &.unpaid {
                font-weight: bold;
                text-transform: capitalize;
                color: red;
              }
            }
            svg {
              font-size: 2rem;
            }
          }
        }
      }
    }

    .table-links {
      display: flex;
      a {
        margin-right: 0.25rem;
      }
    }
  }
}
.invoice-manager {
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 100%;
  background: rgb(17, 17, 17);
  padding: 0rem 1rem;
  button {
    background: $main-color-green;
    color: white;
    margin-right: 1rem;
  }
}

@include responsive(small) {
}

@include responsive(medium) {
  .my-invoices {
    display: grid;
  }
  .invoice-list-container {
    margin-top: 2rem;
    .invoice-list-heading {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      padding: 1rem;
      span {
        &:nth-child(1),
        &:nth-child(6) {
          display: flex;
        }
      }
    }
    .invoice-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      div {
        padding: 0.5rem 0rem;
        &:nth-child(1),
        &:nth-child(6) {
          display: flex;
        }
      }
    }
  }
}

@include responsive(large) {
}
