@import "../../styles/style.scss";

.my-account-page {
  display: grid;
  .account-navigation-container {
    display: grid;
    justify-content: left;
    div {
      display: flex;
      padding: 0.5rem 0rem 0.5rem 0rem;
      margin-bottom: 0.25rem;
    }
    button {
      word-wrap: none;
      word-break: keep-all;
      width: max-content;
      margin: 0rem;
      padding: 0rem;
      margin-right: 1rem;
      background: transparent;
      color: rgb(130, 130, 130);
      font-weight: bold;
      font-size: 1rem;
      &.active {
        text-decoration: underline;
        color: black;
      }
    }
  }
  .contact-details-invoice {
    margin-bottom: 1rem;
    ul {
      &.saved-invoice-contact-details {
        margin-bottom: 0.5rem;
      }
      &.item-details-invoice {
        display: flex;
        li {
          display: flex;
          width: 100%;
          align-items: center;
          align-content: center;
          padding: 0.5rem 0rem;
          font-size: 0.9rem;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
          button {
            margin: 0rem;
            margin-right: 1rem;
          }
          &.title {
            font-size: 1rem;
            font-weight: bold;
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
      }
    }
  }
  h3 {
    margin-bottom: 0.25rem;
  }
  .details-container {
    display: grid;
    grid-template-columns: 1;
    margin-bottom: 1.5rem;
    align-items: flex-start;
    overflow-x: hidden;
    box-shadow: 0px 2px 8px rgba($color: #000000, $alpha: 0.125);
    border-radius: 0.25rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    .title-head {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 1.25rem 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
    }
    .title-foot {
      display: flex;
      margin: 0rem;
      align-items: center;
      padding: 0rem 1rem;
      justify-content: right;
      .actions {
        display: flex;
        button {
          margin: 0rem;
          margin-bottom: 1rem;
        }
      }
    }
    .form-container {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 0.75rem;
      align-items: center;
      padding: 1.25rem 1rem;
      span {
        font-size: 0.9rem;
      }
    }
    .danger-container {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 0.75rem;
      align-items: center;
      padding: 1.25rem 1rem;
      div {
        background: rgba($color: #ffaeae, $alpha: 1);
        padding: 1rem;
        border-radius: 0.25rem;
        border: 1px solid rgba($color: #ff3c3c, $alpha: 1);
      }
      p {
        font-size: 0.9rem;
        font-weight: bold;
        color: #ff3c3c;
      }
      button {
        display: grid;
        align-content: center;
        margin-top: 1rem;
        background: transparent;
        border: 1px solid rgba($color: #ff3c3c, $alpha: 1);
        color: #ff3c3c;
        font-weight: bold;
        height: 1.75rem;
      }
    }
  }
}

@include responsive(medium) {
  .my-account-page {
    .details-container {
      .form-container {
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        align-content: flex-start;
      }
    }
  }
}
