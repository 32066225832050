@import "../../styles/style.scss";

.desc {
  display: grid;
  width: 100%;
  textarea {
    height: 20vh;
    width: 100%;
    padding: 1rem;
    margin-bottom: 0.75rem;
    resize: none;
    background: rgba($color: #ececec, $alpha: 1);
    border: none;
    border-radius: 0.25rem;
  }
  label {
    margin-bottom: 0.5rem;
  }
  input {
    margin-bottom: 0.75rem;
  }
}
button {
  margin-top: 0.5rem;
  margin-right: 1rem;
}
