@import "../../styles/style.scss";

.list-card-item {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0.5rem 0rem;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
  &:nth-child(1) {
    margin-top: 0.125rem;
  }
  &:hover {
    cursor: pointer;
  }
  div {
    display: grid;
    svg {
      font-size: 2.125rem;
      margin-right: 0.5rem;
      color: $main-color-blue;
      &.log {
        color: $main-color-green;
      }
      &.invoice-icon-style {
        color: rgba($color: #000000, $alpha: 0.25);
      }
    }
  }
  .card-details {
    display: flex;
    text-transform: capitalize;
    font-size: 0.8rem;
    font-weight: 500;
    color: #333;
    &:nth-child(even) {
      font-weight: normal;
      font-size: 0.75rem;
      margin-top: 0.125rem;
      color: #777;
    }
    div {
      display: flex;
      height: 100%;
      width: 100%;
      &:nth-child(2) {
        justify-content: right;
      }
    }
  }
}
