@import '../styles/style.scss';

.login-page {
    display: grid;
    width: 100%;
    min-height: 100vh;
    background: black;
    p {
        padding: 1rem 2rem;
        line-height: 1.25rem;
        color: rgb(102, 102, 102);
        font-size: .75rem;
        text-align: center;
    }
  }
.login-form {
    display: grid;
    padding: 1rem 1rem;
    color: rgb(50, 50, 50);
    align-items: flex-start;
    align-content: flex-start;
    .register-button-wrapper {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: .75rem;
        color: rgb(159, 159, 159);
        .back-svg {
            margin-right: .5rem;
            font-size: 1.5rem;
            color: rgb(159, 159, 159);

        }
        &:hover {
            cursor: pointer;
        }
    }
    span {
        justify-self: center;
        color: white;
        font-size: .85rem;
    }
    h3 {
        color: white;
        margin: 1rem 0rem;
        font-weight: normal;
    }
    label {
        margin-top: .25rem;
        color: white;
        font-size: .75rem;
    }
    img {
        margin: 2rem 0rem;
        width: 50%;
        justify-self: center;
    }
    a {
        color: #5dcc89;
        text-decoration: none;
        font-size: .75rem;
        margin: .5rem 0rem;
        font-weight: 300;
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 2.5rem;
        border: none;
        font-weight: bold;
        border-radius: .25rem;
        margin: 1rem 0rem;
        font-size: .75rem;
        box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .5);
        &.login {
            margin: .5rem 0rem .5rem 0rem;
            background: $main-color-blue;
            color: white;
        }
        &.google {
            display: flex;
            position: relative;
            align-items: center;
            align-content: center;
            align-self: center;
            margin: .5rem 0rem 1rem 0rem;
            background: transparent;
            border: 1px solid rgb(63, 63, 63);
            font-weight: 300;
            color: white;
            svg {
                position: absolute;
                left: 2rem;
            }
        }
    }
    input {
        height: 2.5rem;
        width: 100%;
        margin: .25rem 0rem;
        padding: 0rem .5rem;
        background: black;
        border: none;
        outline: none;
        color: white;
        border: 1px solid rgb(63, 63, 63);
        border-radius: .125rem;
        &:active, &:focus {
            background: transparent;
        }
        ::placeholder {
            color: rgb(148, 148, 148);
        }
        &:-webkit-autofill {
        -webkit-text-fill-color: rgb(148, 148, 148);
        -webkit-box-shadow: 0 0 0px 2rem #000 inset;
        }
    }
}

@include responsive(small) {

}

@include responsive(medium) {
    .login-page {
        display: grid;
        margin: auto;
        width: 500px;
        min-height: 100vh;
        background: black;
        p {
            padding: 1rem 2rem;
            line-height: 1.25rem;
            color: rgb(102, 102, 102);
            font-size: .75rem;
            text-align: center;
        }
      }
      .login-form { 
        margin-top: 3rem;
        box-shadow: 0px 4px 20px rgba($color: #979797, $alpha: .1);
      }
}

@include responsive(large) {

}