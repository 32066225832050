@import "../../styles/style.scss";

.dashboard {
  display: grid;
  margin-top: 2rem;
  .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  a {
    display: grid;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    text-decoration: none;
  }

  .recent-service-log-container {
    margin-top: 0.25rem;
  }
  .started-services-wrapper {
    display: grid;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
    .service-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      text-transform: uppercase;
      color: black;
      padding: 0.5rem 0rem;
      svg {
        font-size: 1.5rem;
        color: $main-color-green;
      }
    }
    .content {
      .ongoing-service-card {
        display: grid;
        font-size: 0.8rem;
        background: white;
        border: 1px solid rgba($color: #000000, $alpha: 0.125);
        border-radius: 0.35rem;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 0.8rem;
          padding: 0.5rem 0.5rem;
          background: $main-color-blue;
          color: white;
          border-top-right-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
          .manage {
            display: flex;
            align-items: flex-start;
            align-content: center;
            font-size: 0.5rem;
            text-decoration: underline;
            // ANIMATE HERE
            .animated-div {
              height: 0.6rem;
              width: 0.6rem;
              border-radius: 1rem;
              background: rgba($color: #7bff00, $alpha: 1);
              animation: blink infinite 2000ms linear;
            }
          }
        }
        // NEEDED FOR NON OVERLAPPING OF BORDERS
        div {
          &:nth-last-child(1) {
            cursor: pointer;
            div {
              &:last-of-type {
                border-bottom: none;
              }
            }
          }
        }
        .card-content {
          display: grid;
          grid-template-columns: 1fr 1fr auto;
          margin-top: 0.25rem;
          padding: 0.5rem;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.125);
          align-items: center;
          svg {
            font-size: 1.125rem;
          }
          ul {
            display: grid;
            li {
              display: flex;
              color: #777;
              font-size: 0.8rem;
              svg {
                font-size: 0.85rem;
                color: $main-color-green;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: rotate(90deg);
    opacity: 0.5;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0;
  }
  75% {
    transform: rotate(270deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@include responsive(small) {
}

@include responsive(medium) {
  .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}

@include responsive(large) {
}
