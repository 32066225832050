@import '../styles/style.scss';

.updates {
    display: grid;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    ul {
        margin: .5rem 0rem;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-right: .5rem ;
            }
            &.header {
                font-weight: bold;
                color: rgba($color: #000000, $alpha: .75);
                font-style: italic;
                font-size: .9rem;
            }
        }
    }

    img {
        justify-self: center;
        height: 3rem;
    }
    p {
        text-align: center;
        font-size: .85rem;
        margin: .25rem 0rem;
        &.header {
                margin-top: .75rem;
                font-size: .9rem;
            font-weight: bold;
        }
    }
    h3 {
        &:nth-child(even) {
            margin-top: 1rem;
        }
    }
}